import React from 'react';
import HomeSVG from '../SVG/HomeSVG';
import TrophySVG from '../SVG/TrophySVG';
import './Step.css';

function Steps({step,updateStep,stepsCompleted}) {
    return (
        <div className="step-container">
                <div className="step-border"></div>
                <div className={`extra-step extra-step-1 ${(step===0) ? 'active':''} ${(stepsCompleted.includes(0)=== true) ? 'completed':''}`} onClick={() => updateStep(0)}><HomeSVG/></div>
                <div className={`step ${(step===1) ? 'active':''} ${(stepsCompleted.includes(1)=== true && step !== 1) ? 'completed':''}`} onClick={() => updateStep(1)}>1</div>
                <div className={`step ${(step===2) ? 'active':''} ${(stepsCompleted.includes(2)=== true && step !== 2) ? 'completed':''}`} onClick={() => updateStep(2)}>2</div>
                <div className={`step ${(step===3) ? 'active':''} ${(stepsCompleted.includes(3)=== true && step !== 3) ? 'completed':''}`} onClick={() => updateStep(3)}>3</div>
                <div className={`step ${(step===4) ? 'active':''} ${(stepsCompleted.includes(4)=== true && step !== 4) ? 'completed':''}`} onClick={() => updateStep(4)}>4</div>
                <div className={`step ${(step===5) ? 'active':''} ${(stepsCompleted.includes(5)=== true && step !== 5) ? 'completed':''}`} onClick={() => updateStep(5)}>5</div>
                <div className={`extra-step extra-step-2 ml-2 md:ml-4 ${(step===6) ? 'active':''}`} onClick={() => updateStep(6)}><TrophySVG/></div>
            </div>
    );
}

export default Steps;