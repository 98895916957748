import React from 'react';
import withStep from '../HOC/withStep';
import Nav from '../Nav/Nav';
import LeftSection from '../LeftSection/LeftSection';
import TextWithIconCardRadio from '../Cards/TextWithIconCardRadio';
import { questions, question4 } from '../../utils/questions';
import Copyright from '../Copyright/Copyright';
import QuestionHeader from '../QuestionHeader/QuestionHeader';
import Buttons from '../Buttons/Buttons';
import './Questions.css';

const Question4 = withStep(({ name, values, errors, prevStep, nextStep, setError, handleChange, next, prev, updateStep }) => {
    return (
        <div className="main-wrapper question-four ios-safari-wrapper">
             {(values.innerWidth <= 768) ?  <div className='fixed z-30 w-full mobile-wrapper lg:hidden'>
                <Nav values={values} updateStep={updateStep} />
                <LeftSection values={values} />
                <QuestionHeader name={name} values={values} errors={errors} question={questions.question4}/>
            </div> : null}
            <div className="submain-wrapper">
                {(values.innerWidth > 768) ? <Nav values={values} updateStep={updateStep} /> : ''}
                <div
                    className="question-wrapper">
                    {(values.innerWidth > 768) ? <LeftSection values={values} /> : null}
                    <div className="relative col-span-2 part-two-content">
                        <div className="right-content">
                            {(values.innerWidth > 768) ? <QuestionHeader name={name} values={values} errors={errors} question={questions.question4} /> : ''}
                            <div className="question-4">
                                {question4.map(({ label, value, icon, tooltip }, index) => {
                                    return (
                                        <TextWithIconCardRadio
                                            key={index}
                                            name={name}
                                            value={value}
                                            label={label}
                                            values={values}
                                            handleChange={handleChange}
                                            icon={icon}
                                            questionmark
                                            tooltip={tooltip}
                                        />
                                    )
                                })}
                            </div>
                            <div className="button-wrapper md:mt-8 xl:mt-16 oxl:mt-28">
                                <Buttons name={name} values={values} prev={prev} next={next} tooltip="Please select atleast one answer" />
                            </div>
                        </div>
                        <Copyright position="center" />
                    </div>
                </div>
            </div>
        </div>
    );
})

export default Question4;