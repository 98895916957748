export const questions = {
    question1:{
        title:"What persona best matches your role?",
        subtitle:"Select the best fit."
    },
    question2:{
        title:"Select your industry and annual revenue.",
        //subtitle:"Select your industry."
    },
    question3:{
        title:"What are your biggest vulnerability management challenges?",
        subtitle:"Select up to three.",
        maxrank:5,
        selectedlimit:3,
    },
    question4:{
        title:"How are you addressing those concerns today?",
        subtitle:"Select one."
    },
    question5:{
        title:"What are your top vulnerability management program goals?",
        subtitle:"Select up to three.",
    },
}


export const question1 = [
    {
        label:"CISO, CIO, VP Security",
        value:"Decision-maker",
        desc:" <strong>I manage the budget</strong>, make investment decisions, and <strong>report to the board and C-Suite</strong> how we <strong>reduce org-level risk</strong>. I focus on <strong>aligning Security or IT initiatives</strong> with business goals.",
        img:'fox'
    },
    {
        label:"Director of Security",
        value:"Champion",
        desc:"<strong>I oversee all Security</strong> teams and work to align our infrastructure to best practices. I’m very focused on <strong>ensuring we communicate and collaborate effectively with IT.</strong> ",
        img:'lion'
    },
    {
        label:"Security Analyst",
        value:"Daily User",
        desc:"<strong>I monitor vulnerabilities daily</strong>, and am always looking for easier and <strong>more efficient</strong> ways to reduce risk. I also focus on <strong>integrating VM</strong> with other tools and ticketing systems.",
        img:'panda'
    },
    {
        label:"IT Ops Manager",
        value:"IT influencer",
        desc:"There’s not enough time in the day for my team and I to patch everything, and I have to <strong>prioritize our efforts.</strong>",
        img:'unicorn'
    },
];

export const question21 = [
    {
        label:"Finance/Insurance",
        value:"Fins/Ins",
        icon:"finance",
        img:"finance"
    },
    {
        label:"Professional Services",
        value:"PS",
        icon:"pservices",
        img:"pservices"
    },
    {
        label:"Hospitality",
        value:"Hosp",
        icon:"hospitality",
        img:"hospitality"
    },
    {
        label:"Healthcare/Pharma",
        value:"HC/Pharma",
        icon:"healthcare",
        img:"pharma"
    },
    {
        label:"Manufacturing",
        value:"Mfg",
        icon:"manufacturing",
        img:"manufacturing"
    },
    {
        label:"Energy",
        value:"Energy",
        icon:"energy",
        img:"energy"
    },
    {
        label:"Technology",
        value:"Tech",
        icon:"technology",
        img:"technology"
    },
    {
        label:"Automotive",
        value:"Auto",
        icon:"automotive",
        img:"automotive"
    },
    {
        label:"Government",
        value:"Govt",
        icon:"government",
        img:"government"
    },
    {
        label:"",
        value:"dummy",
        icon:"",
        img:""
    },
    {
        label:"Other Industries",
        value:"Other",
        icon:"other",
        img:"other"
    },
]



export const question2= [
    {
        label:"Finance/Insurance",
        value:"Fins/Ins",
        icon:"finance",
        img:"finance"
    },
    {
        label:"Professional Services",
        value:"PS",
        icon:"pservices",
        img:"pservices"
    },
    {
        label:"Hospitality",
        value:"Hosp",
        icon:"hospitality",
        img:"hospitality"
    },
    {
        label:"Healthcare/Pharma",
        value:"HC/Pharma",
        icon:"healthcare",
        img:"pharma"
    },
    {
        label:"Manufacturing",
        value:"Mfg",
        icon:"manufacturing",
        img:"manufacturing"
    },
    {
        label:"Energy",
        value:"Energy",
        icon:"energy",
        img:"energy"
    },
    {
        label:"Technology",
        value:"Tech",
        icon:"technology",
        img:"technology"
    },
    {
        label:"Automotive",
        value:"Auto",
        icon:"automotive",
        img:"automotive"
    },
    {
        label:"Government",
        value:"Govt",
        icon:"government",
        img:"government"
    },
    
    {
        label:"Other Industries",
        value:"Other",
        icon:"other",
        img:"other"
    },
]








export const question3 = [
    {
        label:"Too many high-risk vulnerabilities with no definitive prioritization",
        value:"Prioritization",
        icon:"priority",
        rank:3
    },
    {
        label:"Wasting resources chasing vulnerabilities that don’t pose a real threat",
        value:"Fixing low-risk vulns",
        icon:"resources",
        rank:4
    },
    {
        label:"Difficulty conveying progress to leadership",
        value:"Reporting",
        icon:"leadership",
        rank:1
    },
    {
        label:"Conflicts between Security and IT over what to remediate and why",
        value:"Sec-IT friction",
        icon:"communication",
        rank:2
    },
    {
        label:"Little or no actionable insight into emerging threats",
        value:"Threat insight",
        icon:"threat",
        rank:5
    }
]

export const question4 = [
    {
        label:"CVSS or scanner scores drive prioritization",
        value:"Evolving",
        icon:"scanner",
        tooltip:"You’re focused on fixing high-risk vulns. Security drives remediation, though there may be disagreement with IT over priorities."
    },
    {
        label:"External CVE and threat intel provides useful insight",
        value:"Intelligent",
        icon:"insights",
        tooltip:"You’re focused on reducing risk scores. Intel helps justify your fix list choices, which helps your relationship with IT."
    },
    {
        label:"Intel and data science help reduce risk org-wide",
        value:"Operational",
        icon:"risk",
        tooltip:"A self-service remediation environment and SLAs based on your organization’s risk tolerance define your program."
    },
    {
        label:"Systematic, proactive VM operation",
        value:"Optimized",
        icon:"systematic",
        tooltip:"You’re focused on maintaining an ongoing response to risk. A self-service remediation environment and SLAs based on your organization’s risk tolerance define your program."
    },
]

export const question5 = [
    {
        label:"Prioritize vulns by real-world risk",
        value:"Prioritization",
        icon:"goals"
    },
    {
        label:"Simplify C-level + board reporting",
        value:"Reporting",
        icon:"reporting"
    },
    {
        label:"Centralize vuln management",
        value:"Centralize mgmt",
        icon:"management"
    },
    {
        label:"Include applications in VM program",
        value:"App sec",
        icon:"vmprogram"
    },
    {
        label:"Improve overall security posture",
        value:"Improve risk posture",
        icon:"securityposture"
    },
    {
        label:"Maintain current risk posture",
        value:"Keep risk posture",
        icon:"riskposture"
    },
    {
        label:"Analyze risk using AI + machine learning",
        value:"AI+ML",
        icon:"ml"
    },
    {
        label:"Create self-serve patch environment for IT",
        value:"Self-service",
        icon:"itenvironment"
    },
    {
        label:"Gain insights into emerging threats",
        value:"Threat insight",
        icon:"emergingthreats"
    },
    {
        label:"Unite Security + IT on common objectives",
        value:"Unite Sec+IT",
        icon:"objectives"
    },
    {
        label:"Improve remediation SLA performance",
        value:"SLAs",
        icon:"perfomance"
    },
    {
        label:"Automate and optimize VM workflows",
        value:"Automation",
        icon:"workflows"
    },

]
