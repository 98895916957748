import React, { useEffect, useRef } from 'react';
import Nav from '../Nav/Nav';
import Contact from './Contact';
import foxwithcape from '../../images/final/foxwithcape.svg';
import unicornwithcape from '../../images/final/unicornwithcape.svg';
import lionwithcape from '../../images/final/lionwithcape.svg';
import pandawithcape from '../../images/final/pandawithcape.svg';
import evolving from '../../images/final/evolving.svg';
import intelligent from '../../images/final/intelligent.svg';
import operational from '../../images/final/operational.svg';
import optimized from '../../images/final/optimized.svg';
import pinpoint from '../../images/final/pinpoint.svg';
import { final } from '../../utils/final';
import { question1, question4 } from '../../utils/questions';
import Copyright from '../Copyright/Copyright';
import './Final.css';

const images = {
    fox: foxwithcape,
    unicorn: unicornwithcape,
    lion: lionwithcape,
    panda: pandawithcape
}

const icons = {
    evolving,
    intelligent,
    operational,
    optimized
}
function getIconByKey(key) {
    return icons[key];
}
function getImageByKey(key) {
    return images[key];
}

const onSubmitButtonClick = (formRef, setShowModal) => {
    if (formRef.current) {
        formRef.current.handleSubmit();
    }
}

const Final = ({ values, onFormSubmit, updateStep, setRequestId, setChanged }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const formRef = useRef()
    const [activeTag, setActiveTag] = React.useState("");
    const questionOneObj = question1.find(o => o.value === values['persona']);
    const questionFourObj = question4.find(o => o.value === values['vmsolution']);
    const defenderObj = final.find(o => o.value === questionFourObj.value);
    const end = Date.now() + (2 * 1000);
    const colors = ['#8084F6', '#3D387F'];
    let requestId;
    useEffect(() => {
        (function frame() {
            window.confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            window.confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestId = requestAnimationFrame(frame);
                setRequestId(requestId);
            }
        }());
    }, [values.step])
    return (
        <>
            <div className="main-wrapper main-final-wrapper overflow-hidden">
                <div className="submain-wrapper">
                    <Nav values={values} updateStep={updateStep} />
                    <div className="final-wrapper-container">
                        <div className="final-wrapper">
                            <div className="headingText">
                                <h1 className="text-bluebell text-center">Congratulations!</h1>
                                <div className="subheadingText">
                                    <span>You’re an</span>
                                    <span className="bg-finalBadge">
                                        <img src={getIconByKey(defenderObj.icon)} className="w-full" alt="" />
                                    </span>
                                    <span>{defenderObj.value} Defender.</span>
                                </div>
                            </div>
                            <div className="descriptionText">
                                <p>
                                    {defenderObj.description}
                                </p>
                            </div>
                            <div className="button-section">
                                <button className="btn-continue" onClick={() => setShowModal(true)}>Get your custom assesment now</button>
                                <p className="hover-text-1 pt-2 lg:hidden">Tap over the plus signs to learn more about each defender type.</p>
                                <p className="hover-text-2 pt-2 hidden lg:block">Hover over the plus signs to learn more about each defender type.</p>
                            </div>
                            <div className="tiles-step-container">
                                <div className="final-arrow-caption">
                                    <p className="text-caption italic text-white">Lower risk with predictive data- driven intel</p>
                                </div>
                                {final.map(({ value, icon, height, tag, tooltipShort }, index) => {
                                    return (
                                        <>
                                            <div className={`tiles-step ${height} ${(value === defenderObj.value) ? 'active' : ''}`}>
                                                {(value === defenderObj.value) ?
                                                    <div className={`cape-avatar-container ${questionOneObj.img}`}>
                                                        <img src={getImageByKey(questionOneObj.img)} alt="" />
                                                        {/* <img src={pandawithcape} alt=""/> */}
                                                        <span className="badge">
                                                            <img src={getIconByKey(icon)} alt="" />
                                                        </span>
                                                    </div> : null}
                                                {(value != defenderObj.value && tag === "fourth") ?
                                                    <div className="pinpoint-container">
                                                        <img src={pinpoint} alt="" />
                                                    </div> : null
                                                }
                                                <p className="tiles-step-text" onMouseEnter={() => setActiveTag(tag)} onMouseLeave={() => setActiveTag("")}>{value}</p>
                                            </div>
                                            {(tooltipShort.length > 0) ? <div className={`cape-avatar-hover-container ${tag} ${tag === activeTag ? 'opacity-100' : "opacity-0"}`}>
                                                <div className='p-5 text-center'>
                                                    <h6>{value}</h6>
                                                    <p>{tooltipShort}</p>
                                                </div>
                                            </div> : null}
                                        </>
                                    )
                                })}

                            </div>
                            {/* <div className="self-start text-center py-6 lg:hidden">
                                <button className="btn-continue" onClick={() => setShowModal(true)}>Get your custom assesment now</button>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
            {showModal ? (
                <>
                    <div
                        className="model-wrapper"
                    >
                        <div className="modal-wrapper-body">
                            {/*content*/}
                            <div className="border-0 border-modal relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="modal-header flex items-center justify-between p-5 bg-cultured">
                                    <h3 className="text-modal font-bold font-robotoslab">
                                        Where should we send your custom assessment?
                                    </h3>
                                    <button onClick={() => setShowModal(false)} className="btn-close ml-auto">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.2608 7.49787L14.6306 2.14055C14.8658 1.90539 14.9979 1.58646 14.9979 1.2539C14.9979 0.921347 14.8658 0.602412 14.6306 0.36726C14.3955 0.132107 14.0765 0 13.744 0C13.4114 0 13.0925 0.132107 12.8573 0.36726L7.5 5.73707L2.14268 0.36726C1.90752 0.132107 1.58859 -2.47773e-09 1.25603 0C0.923478 2.47773e-09 0.604543 0.132107 0.369391 0.36726C0.134239 0.602412 0.00213119 0.921347 0.00213119 1.2539C0.00213119 1.58646 0.134239 1.90539 0.369391 2.14055L5.7392 7.49787L0.369391 12.8552C0.252343 12.9713 0.159441 13.1094 0.0960411 13.2616C0.0326416 13.4138 0 13.577 0 13.7418C0 13.9067 0.0326416 14.0699 0.0960411 14.2221C0.159441 14.3743 0.252343 14.5124 0.369391 14.6285C0.485482 14.7455 0.6236 14.8384 0.775777 14.9018C0.927954 14.9652 1.09118 14.9979 1.25603 14.9979C1.42089 14.9979 1.58411 14.9652 1.73629 14.9018C1.88847 14.8384 2.02659 14.7455 2.14268 14.6285L7.5 9.25867L12.8573 14.6285C12.9734 14.7455 13.1115 14.8384 13.2637 14.9018C13.4159 14.9652 13.5791 14.9979 13.744 14.9979C13.9088 14.9979 14.072 14.9652 14.2242 14.9018C14.3764 14.8384 14.5145 14.7455 14.6306 14.6285C14.7477 14.5124 14.8406 14.3743 14.904 14.2221C14.9674 14.0699 15 13.9067 15 13.7418C15 13.577 14.9674 13.4138 14.904 13.2616C14.8406 13.1094 14.7477 12.9713 14.6306 12.8552L9.2608 7.49787Z" fill="black" />
                                        </svg>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <Contact formRef={formRef} onFormSubmit={onFormSubmit} setIsSubmitting={setIsSubmitting} setChanged={setChanged} />
                                    <div className="flex flex-col items-center justify-center p-6">
                                        <button
                                            className="btn-continue flex items-center justify-center"
                                            type="button"
                                            onClick={() => onSubmitButtonClick(formRef, setShowModal)}
                                        >
                                            {(isSubmitting) ? <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg> : null}
                                            Submit
                                        </button>
                                        {(values.statusMessage && !values.changed) ? <p className={`mt-4 ${values.statusAPI ? '':'text-red-600 font-lato font-bold'}`}>{values.statusMessage}</p> : null}
                                    </div>
                                </div>
                                {/*footer*/}
                                <Copyright position="center" />
                            </div>
                        </div>
                    </div>
                    <div className="overlay-background opacity-50 fixed inset-0 bg-arsenic"></div>
                </>
            ) : null}
        </>
    );
}

export default Final;