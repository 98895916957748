import React, {useLayoutEffect } from 'react';
import withStep from '../HOC/withStep';
import Nav from '../Nav/Nav';
import { question1 } from '../../utils/questions';
import AvtarCard from '../Cards/AvtarCard';
import { animateScroll as scroll } from 'react-scroll';
import Error from '../Error/Error';
import Copyright from '../Copyright/Copyright';
import './Questions.css'
import Buttons from '../Buttons/Buttons';

const Question1 = withStep(({ name, values, errors, prevStep, nextStep, setError, handleChange, next, prev, updateStep }) => {
    useLayoutEffect(() => {
        scroll.scrollToTop();
    }, [])
    return (
        <div className="main-wrapper question-1 ios-safari-wrapper">
            {(values.innerWidth <= 768) ?  <div className='fixed z-30 w-full mobile-wrapper lg:hidden'>
                <Nav values={values} updateStep={updateStep} />
                <div className="header-wrapper">
                    <h1 className="mb-4 xl:mb-4 oxl:mb-8">What persona best matches your role?</h1>
                    <div className="subtitle-1">
                        <p className="mb-3">Select the best fit.</p>
                        {errors[name] ? <Error message="You have not selected anything!" position="center" /> : null}
                    </div>
                </div>
            </div> : null}
            {(values.innerWidth > 768) ? <Nav values={values} updateStep={updateStep} /> : ''}
            <div className="submain-wrapper">
                <div className="content">
                   {(values.innerWidth > 768) ? <div className="grid grid-cols-1 px-4 mt-8 text-center md:px-0 xl:mt-0 justify-items-center lg:text-left">
                        <h1 className="mb-4 xl:mb-4 heading-question-1">What persona best matches your role?</h1>
                        <div className="subtitle-1">
                            <p className="mb-3">Select the best fit.</p>
                            {errors[name] ? <Error message="You have not selected anything!" position="center" /> : null}
                        </div>
                    </div> : null}
                    <div className="role-container">
                        {question1.map(({ label, value, desc, img }, index) => {
                            return (
                                <AvtarCard
                                    key={index}
                                    name={name}
                                    label={label}
                                    value={value}
                                    desc={desc}
                                    handleChange={handleChange}
                                    img={img}
                                    values={values}
                                />
                            )
                        })}
                    </div>
                    <div className="question-button-wrapper">
                        <Buttons name={name} values={values} prev={prev} next={next} tooltip="Please select atleast one answer" />
                    </div>
                </div>
                <Copyright position="center" />
            </div>
           
        </div>

    );
})

export default Question1;