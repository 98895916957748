import React from 'react';
import questionmarksvg from '../../images/questionmark.svg';
import finance from '../../images/question2/icons/finance.svg';
import pservices from '../../images/question2/icons/pservices.svg';
import hospitality from '../../images/question2/icons/hospitality.svg';
import healthcare from '../../images/question2/icons/healthcare.svg';
import manufacturing from '../../images/question2/icons/manufacturing.svg';
import energy from '../../images/question2/icons/energy.svg';
import technology from '../../images/question2/icons/technology.svg';
import automotive from '../../images/question2/icons/automotive.svg';
import government from '../../images/question2/icons/government.svg';
import other from '../../images/question2/icons/other.svg';
import scanner from '../../images/question4/icons/scanner.svg';
import insights from '../../images/question4/icons/insights.svg';
import systematic from '../../images/question4/icons/systematic.svg';
import risk from '../../images/question4/icons/risk.svg';

const images = {
    finance,
    pservices,
    hospitality,
    healthcare,
    manufacturing,
    energy,
    technology,
    automotive,
    government,
    scanner,
    insights,
    systematic,
    risk,
    other
}

function getImageByKey(key) {
    return images[key];
}

function TextWithIconCardRadio({ name, label, value, values, icon, handleChange, questionmark, tooltip }) {
    return (
        
        <div className={`px-4 md:px-0 ${value ==="dummy" ? 'dummy': null } ${(questionmark) ? 'flex question-4-tooltip':''}`}>
            <label className={`${(questionmark) ? 'w-full pr-2':''}`}>
                <input type="radio" name={name} value={value} onChange={handleChange(name)}   checked={values[name] === value} className="hidden" />
                {value ==="dummy" ? null:<div className="flex items-center justify-between px-3 border cursor-pointer radio-input border-alto rounded-xl h-14 md:px-5">                    
                    <p className="text-button">{label}</p>
                    <img className="justify-self-end" src={getImageByKey(icon)} alt="" />
                </div>}
            </label>
            {questionmark ? <div className="flex tooltip right">
                <img src={questionmarksvg} className="questionmark" alt="" />
                <span className="tooltip-content">{tooltip}</span>
            </div> : null}
            
        </div>
    );
}

export default TextWithIconCardRadio;