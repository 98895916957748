import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

let schema = yup.object().shape({
    firstname: yup.string().required("Required"),
    lastname: yup.string().required("Required"),
    companyemail: yup.string().email().required("Required"),
    company: yup.string().required("Required")
});

const Contact = ({formRef, onFormSubmit,setIsSubmitting,setChanged}) => {
    
    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={{ firstname: '', lastname: '', companyemail: '', company: '', isSubscribe: false }}
                validationSchema={schema}
                onSubmit={onFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    dirty
                    /* and other goodies */
                }) => {
                    setIsSubmitting(isSubmitting)
                    return(
                    
                    <form>
                        <div className="grid grid-cols-2 gap-6">
                            <div className="col-span-2 sm:col-span-1">
                                <input
                                    type="text"
                                    name="company"
                                    className="input"
                                    onChange={e => {
                                        setChanged(true);
                                        handleChange(e);
                                      }}
                                    onBlur={handleBlur}
                                    value={values.company}
                                    placeholder="Company name"
                                    className="final-form-input input"
                                />
                                {
                                    (touched.company && errors.company) ? <p className="mt-2 capitalize font-lato text-redorange text-xsmall">{errors.company}</p> : null
                                }
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <input
                                    type="email"
                                    name="companyemail"
                                    className="input"
                                    onChange={e => {
                                        setChanged(true);
                                        handleChange(e);
                                      }}
                                    onBlur={handleBlur}
                                    value={values.companyemail}
                                    placeholder="Company Email"
                                    className="final-form-input input"
                                />
                                {
                                    (touched.companyemail && errors.companyemail) ? <p className="mt-2 capitalize font-lato text-redorange text-xsmall">{errors.companyemail}</p> : null
                                }
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <input
                                    type="text"
                                    name="firstname"
                                    className="input"
                                    onChange={e => {
                                        setChanged(true);
                                        handleChange(e);
                                      }}
                                    onBlur={handleBlur}
                                    value={values.firstname}
                                    placeholder="First Name"
                                    className="final-form-input input"
                                />
                                {
                                    (touched.firstname && errors.firstname) ? <p className="mt-2 capitalize font-lato text-redorange text-xsmall">{errors.firstname}</p> : null
                                }
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <input
                                    type="text"
                                    name="lastname"
                                    className="input"
                                    onChange={e => {
                                        setChanged(true);
                                        handleChange(e);
                                      }}
                                    onBlur={handleBlur}
                                    value={values.lastname}
                                    placeholder="Last Name"
                                    className="final-form-input input"
                                />
                                {
                                    (touched.lastname && errors.lastname) ? <p className="mt-2 capitalize font-lato text-redorange text-xsmall">{errors.lastname}</p> : null
                                }
                            </div>
                            <div className="col-span-2">
                                <div className="flex items-center subscribe-checkbox">
                                    <input type="checkbox" name="isSubscribe" className="w-5 h-5 form-checkbox" value={values.isSubscribe} onChange={handleChange}
                                    onBlur={handleBlur}/>
                                    <label className="pl-2 font-bold font-lato text-button">Want to stay updated on everything risk-based? Subscribe to Kenna's monthly newsletter for the latest security news and expert insight.</label>
                                </div>
                            </div>
                        </div>
                    </form>
                )}}
            </Formik>
        </div>
    );
}

export default Contact;