export const final = [
    {
        value:"Evolving",
        description:"From what you’ve shared, it’s clear you’re starting to evolve your security posture, which is awesome.",
        caption:"Lower risk with predictive data- driven intel",
        tooltip:["Wasting time chasing low-risk vulns","Overwhelmed by CVEs scored as “critical” by CVSS or your scanner",
                "Catching heat from IT teams tired of being handed long fix lists","Reacting to execs panicked over high-profile vulns",
                "Lacking definitive prioritization to save you time and resources"],
        tooltipShort:"Too many mislabeled critical vulnerabilities that can't all be remediated, leaving Security and IT at odds",
        tag:"first",
        height:"h-tile-1",
        icon:"evolving"
    },
    {
        value:"Intelligent",
        description:"From what you’ve shared, you’ve got a leg up on most of your peers because you’re clever enough to use external threat and vulnerability intel to help inform your prioritization and remediation. Well done, you!",
        caption:"Lower risk with predictive data- driven intel",
        tooltip:["Struggling to do more with less","Fixing too many low-risk vulns","Unable to clearly justify fix list choices",
            "Struggling to communicate risk reduction progress to C-levels and board","Reducing risk in some, but not all, areas"],
        tooltipShort:"Fewer vulnerabilities to remediate thanks to risk prioritization driven by threat and vulnerability intel on top of scanner score / CVSS",
        tag:"second",
        height:"h-tile-2",
        icon:"intelligent"
    },
    {
        value:"Operational",
        description:"From what you’ve shared, you’ve got a pretty mature VM thing going, with Security and IT generally aligned around reducing risk, and with your intel-driven operation focusing on the vulns that matter most.",
        caption:"Lower risk with predictive data- driven intel",
        tooltip:["Struggling to clearly communicate progress with execs and the board","Failing to meet remediation SLAs and create a self-serve remediation",
            "Trying to figure out a way to centralize vuln management","Looking to better automate and optimize workflows"],
        tooltipShort:"Security and IT aligned around reducing risk with 'next best action' lists informed by actionable threat and vulnerability intel and data science",
        tag:"third",
        height:"h-tile-3",
        icon:"operational"
    },
    {
        value:"Optimized",
        description:"Wow! You’ve managed to create a security fortress shaped by data-driven prioritization and built around a culture of risk reduction. Despite your success, there are likely some aspects you want to improve on. ",
        caption:"Lower risk with predictive data- driven intel",
        tooltip:["Even fully resourced Security organizations with robust VM programs often lack actionable insight on emerging threats. And wouldn’t it be great to predict which vulns already existing in your infrastructure are likely to be weaponized next? That’s next-level stuff."],
        tooltipShort:"Lowest possible risk profile achieved with all teams collaborating in a state of constant readiness",
        tag:"fourth",
        height:"h-tile-4",
        icon:"optimized"
    },
]