// extracted by mini-css-extract-plugin
export var avatarsContainer = "WrapperIntro-module--avatarsContainer--kwG7X";
export var avtar1 = "WrapperIntro-module--avtar1--UKReV";
export var avtar2 = "WrapperIntro-module--avtar2--eACDR";
export var avtar3 = "WrapperIntro-module--avtar3--bGcP9";
export var avtar4 = "WrapperIntro-module--avtar4--Muocq";
export var badge = "WrapperIntro-module--badge--8FXkD";
export var buildings = "WrapperIntro-module--buildings--EOzbU";
export var cloud = "WrapperIntro-module--cloud--+CnD8";
export var cloudGroup = "WrapperIntro-module--cloudGroup--aR5Ew";
export var clouds = "WrapperIntro-module--clouds--sXFPm";
export var ellipse = "WrapperIntro-module--ellipse--Jy-Go";
export var gridWrapper = "WrapperIntro-module--gridWrapper--jhmGP";
export var leftColumn = "WrapperIntro-module--leftColumn--i6Pp6";
export var leftSectionContainer = "WrapperIntro-module--leftSectionContainer--q3jPu";
export var mainIntroWrapper = "WrapperIntro-module--main-intro-wrapper--ZH0eJ";
export var mainWrapper = "WrapperIntro-module--main-wrapper--9SUTL";
export var partone = "WrapperIntro-module--partone--8VJws";
export var parttwo = "WrapperIntro-module--parttwo--LHMU2";
export var rainbow = "WrapperIntro-module--rainbow--Cd9aj";
export var rainbowColour = "WrapperIntro-module--rainbow-colour--NgF5C";
export var rightColumn = "WrapperIntro-module--rightColumn--ZTba3";