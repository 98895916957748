import * as React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import SurveyForm from "../components/SurveyForm"

const IndexPage = () => (
  <Layout>
    <Seo title="Vulnerability Assessment" />
    <SurveyForm/>
  </Layout>
)

export default IndexPage
