import React from 'react';
import { Link } from 'gatsby';
import './Copyright.css';

function Copyright({ position }) {
    let positionClass;
    if(position === 'center'){
        positionClass = "copyrightCenter"
    }else{
        positionClass = "copyright"
    }
    return (
        <div className={`footer-copyright hidden xl:block ${positionClass}`}>
            <p className="text-xs leading-4 font-robotoslab font-normal">&copy; 2022 Cisco. All Rights Reserved. <a href="https://www.kennasecurity.com/privacy/" target="_blank">Privacy Policy.</a></p>
        </div>
    );
}

export default Copyright;