import React from 'react';

function Cloud(props) {
    return (
        <svg width="108" height="60" viewBox="0 0 108 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M92.5407 59.9999H19.5409C8.7584 59.9999 -1.52588e-05 51.2415 -1.52588e-05 40.459C-1.52588e-05 28.8382 10.2438 19.4761 22.1231 21.1118C25.0289 8.93066 36.1121 0 48.7876 0C59.1179 0 68.4788 5.74563 73.1716 14.8285C74.8722 14.2691 76.6366 13.989 78.4227 13.989C87.6118 13.989 95.1659 21.1767 95.747 30.2162C102.547 31.7006 107.605 37.8118 107.605 44.9356C107.605 53.2428 100.848 60 92.5405 60L92.5407 59.9999Z" fill="white" />
        </svg>

    );
}

export default Cloud;