import React from 'react';
import concern from '../../images/concern.svg';
import './Button.css';

function Buttons({prev,values,name,next,tooltip}) {
    return (
        <>
            <button className="btn-prev" onClick={prev}>PREVIOUS</button>
            <div className='relative justify-self-end'>
                <button className={`btn-next`} disabled={values[name].length === 0 || (values.step===2 && values.companysize === 0)} onClick={() => next(values[name].length !== 0)}>Next</button>
                <div className='error-tooltip'>
                    <img src={concern} alt=""/>
                    <p>{tooltip}</p>
                </div>
            </div>
            
        </>
    );
}

export default Buttons;