import React from 'react';
import leadership from '../../images/question3/icons/leadership.svg';
import communication from '../../images/question3/icons/communication.svg';
import priority from '../../images/question3/icons/priority.svg';
import resources from '../../images/question3/icons/resources.svg';
import threat from '../../images/question3/icons/threat.svg';
import goals from '../../images/question5/icons/goals.svg';
import reporting from '../../images/question5/icons/reporting.svg';
import management from '../../images/question5/icons/management.svg';
import vmprogram from '../../images/question5/icons/vmprogram.svg';
import securityposture from '../../images/question5/icons/securityposture.svg';
import riskposture from '../../images/question5/icons/riskposture.svg';
import ml from '../../images/question5/icons/ml.svg';
import itenvironment from '../../images/question5/icons/itenvironment.svg';
import emergingthreats from '../../images/question5/icons/emergingthreats.svg';
import objectives from '../../images/question5/icons/objectives.svg';
import perfomance from '../../images/question5/icons/perfomance.svg';
import workflows from '../../images/question5/icons/workflows.svg';

const images = {
    leadership,
    communication,
    priority,
    resources,
    threat,
    goals,
    reporting,
    management,
    vmprogram,
    securityposture,
    riskposture,
    ml,
    itenvironment,
    emergingthreats,
    objectives,
    perfomance,
    workflows

}

function getImageByKey(key) {
    return images[key];
}

function TextWithIconCard({ q, index, name, label,value, selected, icon, handleChange,margin }) {
    return (
        <div className={`px-4 md:px-0 w-full md:w-auto ${(margin) ? "mr-3 mb-5" : ""}`}>
            <label>
                <input
                    type="checkbox"
                    id={`${q}-${index}`}
                    name={name}
                    label={label}
                    value={value}
                    checked={selected[name][index]}
                    onChange={(e) => handleChange(e,index)}
                    className="hidden"
                />
                <div className="checkbox-input border border-alto rounded-xl h-14 flex items-center justify-between px-4 cursor-pointer">
                    <p className="text-button">{label}</p>
                    <img className={`justify-self-end ${(margin) ? "pl-2":""}`} src={getImageByKey(icon)} alt="" />
                </div>

            </label>
        </div>
    );
}

export default TextWithIconCard;