import React from 'react';
import buildings from '../../images/intro/buildings.svg';
import unicorn from '../../images/intro/unicorn.svg';
import lion from '../../images/intro/lion.svg';
import fox from '../../images/intro/fox.svg';
import panda from '../../images/intro/panda.svg';
import * as wrapperStyles from './WrapperIntro.module.css';
import ellipse from '../../images/intro/ellipse.svg';
import Nav from '../Nav/Nav';
import evolving from '../../images/final/evolving.svg';
import intelligent from '../../images/final/intelligent.svg';
import operational from '../../images/final/operational.svg';
import optimized from '../../images/final/optimized.svg';
import cloud from '../../images/intro/cloud.svg';

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
}

function WrapperIntro({ values,updateStep,children }) {
    const a = 7; //rainbowcolor 
    const b = 5; //cloud 

    return (
       
            <div className="main-intro-wrapper">
                <Nav values={values} updateStep={updateStep} />
                <div className={wrapperStyles.gridWrapper}>
                    <div className={wrapperStyles.leftColumn}>
                        <div className={wrapperStyles.leftSectionContainer}>
                            <div className={wrapperStyles.partone}>
                                <div className={wrapperStyles.sun}></div>
                                <div className={`${wrapperStyles.rainbow}`}>
                                    {[...Array(a)].map((e, i) => <div key={i} className={wrapperStyles.rainbowColour}></div>)}
                                </div>
                                <div className={wrapperStyles.cloudGroup}>
                                    {[...Array(b)].map((e, i) => <div key={i} className={wrapperStyles.cloud}>
                                        <img src={cloud} alt=""/>
                                    </div>)}
                                </div>
                                <div className={wrapperStyles.buildings}>
                                    <img src={buildings} alt="" />
                                </div>
                            </div>
                            <div className={wrapperStyles.parttwo}>
                                <div className={wrapperStyles.avatarsContainer}>
                                    <div className={wrapperStyles.avtar1}>
                                        <img src={unicorn} alt="" />
                                        <span className={wrapperStyles.badge}>
                                            <img src={optimized} alt=""/>
                                        </span>
                                    </div>
                                    <div className={wrapperStyles.avtar2}>
                                        <img src={lion} alt="" />
                                        <span className={wrapperStyles.badge}>
                                            <img src={intelligent} alt=""/>
                                        </span>
                                    </div>
                                    <div className={wrapperStyles.avtar3}>
                                        <img src={fox} alt="" />
                                        <span className={wrapperStyles.badge}>
                                            <img src={evolving} alt=""/>
                                        </span>
                                    </div>
                                    <div className={wrapperStyles.avtar4}>
                                        <img src={panda} alt="" />
                                        <span className={wrapperStyles.badge}>
                                            <img src={operational} alt=""/>
                                        </span>
                                    </div>
                                    <div className={wrapperStyles.ellipse}>
                                        <img src={ellipse} className="w-full" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={wrapperStyles.rightColumn}>
                        {children}
                    </div>
                </div>
            </div>
       
    );
}

export default WrapperIntro;