import React from 'react';

function TrophySVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="31"
            fill="none"
            viewBox="0 0 28 31"
        >
            <path
                fill="#fff"
                d="M5.333 1h17.333v11.973a8.73 8.73 0 01-2.538 6.158 8.645 8.645 0 01-6.129 2.55 8.646 8.646 0 01-6.127-2.55 8.729 8.729 0 01-2.539-6.158V1zM1 3.177h4.333v7.45a5.637 5.637 0 01-3.129-2.014A5.684 5.684 0 011 5.082V3.177zm21.666 0H27v1.905a5.704 5.704 0 01-1.204 3.538 5.656 5.656 0 01-3.13 2.023V3.177zM5.333 27.823h17.333a1.053 1.053 0 011.008.668c.053.134.079.277.075.42V30H4.25v-1.088a1.068 1.068 0 01.666-1.013c.133-.054.275-.08.418-.076zm6.641 0l.677-6.245-.677 6.245zm4.049 0l-.677-6.245.677 6.245z"
            ></path>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M16.023 27.823l-.677-6.245M5.333 1h17.333v11.973a8.73 8.73 0 01-2.538 6.158 8.645 8.645 0 01-6.129 2.55 8.646 8.646 0 01-6.127-2.55 8.729 8.729 0 01-2.539-6.158V1zM1 3.177h4.333v7.45a5.637 5.637 0 01-3.129-2.014A5.684 5.684 0 011 5.082V3.177zm21.666 0H27v1.905a5.704 5.704 0 01-1.204 3.538 5.656 5.656 0 01-3.13 2.023V3.177zM5.333 27.823h17.333a1.053 1.053 0 011.008.668c.053.134.079.277.075.42V30H4.25v-1.088a1.068 1.068 0 01.666-1.013c.133-.054.275-.08.418-.076zm6.641 0l.677-6.245-.677 6.245z"
            ></path>
        </svg>
    );
}

export default TrophySVG;