import React from 'react';
import fox from '../../images/question1/fox.svg'
import lion from '../../images/question1/lion.svg'
import panda from '../../images/question1/panda.svg'
import unicorn from '../../images/question1/unicorn.svg'
import './AvtarCard.css';

const images = {
    fox,
    lion,
    panda,
    unicorn
}

function getImageByKey(key) {
    return images[key];
}
  

function AvtarCard({ name, label,value, desc, img, values, handleChange }) {
    return (
        <div className="avatar-wrapper">
            <label>
                <input type="radio" name={name} value={value} onChange={handleChange(name)} checked={values.persona === value} className="hidden" />
                <div className="radio-input relative grid grid-cols-1 h-full border border-alto rounded-xl z-30 bg-white p-4 cursor-pointer">
                    <div className="avatar-text bg-bubble" dangerouslySetInnerHTML={{ __html: desc }}>
                        
                    </div>
                    <div className={`absolute bottom-0 right-0 z-0 avatar ${img} md:w-auto`}> 
                        <img src={getImageByKey(img)} alt=""/>
                    </div>
                    <div className="bg-white absolute bottom-0 font-lato font-bold text-paragraph text-center z-20 w-full py-2 border-t rounded-b-xl border-alto">
                        {label}
                    </div>
                </div>
            </label>
        </div>
    );
}

export default AvtarCard;