import React from 'react';

function HomeSVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="31"
            fill="none"
            viewBox="0 0 33 31"
        >
            <path fill="#fff" d="M16.5 1.012L5 12.16V30h23V12.718L16.5 1.012z"></path>
            <path
                stroke="#000"
                strokeLinecap="round"
                d="M1 15.948l4-3.858m27 3.858l-4-3.858m-23 0L16.5 1 28 12.09m-23 0v17.698h23V12.09"
            ></path>
        </svg>
    );
}

export default HomeSVG;