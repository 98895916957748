import React from 'react';
import PropTypes from 'prop-types';
import WrapperIntro from '../Wrapper/WrapperIntro';
import Copyright from '../Copyright/Copyright';

const Intro = ({ values,nextStep,updateStep }) => {

    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    return (
        <WrapperIntro values={values} updateStep={updateStep}>
            <div className="px-6 md:px-10 pt-12 md:pt-16 lg:pt-0 xl:px-20 intro-wrapper">
                <h1 className="mb-4 lg:mb-8">What Defender Type are you?</h1>
                <h2 className="mb-8 lg:mb-10 4xl:max-w-2xl">Find out, and get a custom Vulnerability Management (VM) assessment.</h2>
                <p className="mb-4 lg:mb-8 4xl:max-w-2xl">How you approach cybersecurity says a lot about your program—and your Defender Type.</p>
                <p className="mb-12 lg:mb-20 xl:mb-28 oxl:mb-40 4xl:max-w-2xl">Answer these five questions to receive a custom assessment with the VM action plan needed to level-up your superhero security stance.</p>
                <button className="justify-self-start btn-continue md:my-12 lg:mt-0" onClick={Continue}>Continue</button>
            </div>

            <Copyright position="right" />

        </WrapperIntro>
    );
}

Intro.propTypes = {
    nextStep: PropTypes.func.isRequired,
};

export default Intro;