import React from "react";

export default function withStep(WrappedComponent){
    return class extends React.Component {
        next = (isValid, isFinal) => {
            if(isValid){
              this.props.nextStep();
            }else{
              this.props.setError(this.props.name,true);
            }
          }
        
        prev = e => {
            e.preventDefault();
            this.props.prevStep();
          }

        handleLocalChange = (event,position) => {
            const currentChecked = event.target.checked;
            const updatedCheckedState = this.props.selected[this.props.name].map((item, index) =>
                index === position ? !item : item
            );
            this.props.setSelected(this.props.name,updatedCheckedState,this.props.q, currentChecked);
        }
        
        render() {
          // ... and renders the wrapped component with the fresh data!
          // Notice that we pass through any additional props
          return <WrappedComponent next={this.next} prev={this.prev} handleLocalChange={this.handleLocalChange} {...this.props} />;
        }
      };
}