import React, { Component } from 'react';
import Intro from './Intro';
import Question1 from './Question1';
import Question2 from './Question2';
import Question3 from './Question3';
import Question4 from './Question4';
import Question5 from './Question5';
import Final from './Final';
import { final } from '../../utils/final';
import { question1, question3, question4, question5 } from '../../utils/questions';
import axios from 'axios';

const questions = {
    question3: question3,
    question4: question4,
    question5: question5,
}

class SurveyForm extends Component {

    state = {
        requestId:null,
        step: 0,
        previousStep: 0,
        persona: '',
        industry: '',
        companysize: 0,
        vmchallenges: [],
        vmsolution: '',
        vmgoals: [],
        stepsCompleted:[],
        errors: { persona: false, industry: false, companysize: false, vmchallanges: false, vmsolution: false, vmgoals: false },
        selected: {
            vmchallenges: new Array(question3.length).fill(false),
            vmgoals: new Array(question5.length).fill(false)
        },
        rankTotal: {
            vmchallenges: 0,
        },
        statusAPI:null,
        statusMessage:"",
        changed:"",
        otherIndustries:"",
        innerWidth:typeof window !== "undefined" ? window.innerWidth : ''
    }

    componentDidMount(){
        if(typeof window !== "undefined"){
            window.addEventListener("resize", this.setWidth);
        }
    }

    componentWillUnmount(){
        if(typeof window !== "undefined"){
            window.removeEventListener("resize", this.setWidth)
        }
    }

    setWidth = () => {
        this.setState({innerWidth: window.innerWidth})
    }

    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ previousStep: step, step: step - 1 });
    }

    setChanged = (value) => {
        this.setState({changed:value})
    }

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        let stepCompleted = [...this.state.stepsCompleted];
        if(stepCompleted.includes(step) === false) stepCompleted.push(step);
        this.setState({ step: step + 1, stepsCompleted:stepCompleted});
    }

    //update to step value 
    updateStep = (stepValue) => {
        this.stop();
        window.confetti.reset();
        const { persona, industry, vmchallenges, vmsolution, vmgoals } = this.state;
        const values = [persona, industry, vmchallenges, vmsolution, vmgoals];
        if ((values[stepValue - 1] && values[stepValue - 1].length > 0) || stepValue === 0) {
            this.setState({ step: stepValue })
        }
        //reset state on home icon click
        if (stepValue === 0) {
            this.resetState();
        }
    }

    setRequestId = (requestId) => {
        this.setState({requestId:requestId})
    }

    stop = () => {
        if(this.state.requestId){
            window.cancelAnimationFrame(this.state.requestId)
        }
    }

    resetState = () => {
        this.setState({
            step: 0,
            previousStep: 0,
            persona: '',
            industry: '',
            companysize: 0,
            vmchallenges: [],
            vmsolution: '',
            vmgoals: [],
            stepsCompleted:[],
            errors: { persona: false, industry: false, companysize: false, vmchallanges: false, vmsolution: false, vmgoals: false },
            selected: {
                vmchallenges: new Array(question3.length).fill(false),
                vmgoals: new Array(question5.length).fill(false)
            },
            rankTotal: {
                vmchallenges: 0,
            }
        })
    }

    // Handle fields change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
        this.setError(input, false);
    }

    handleChangeSlider = input => e => {
        this.setState({ [input]: e });
        this.setError(input, false);
    }

    //set error
    setError = (input, bool) => {
        this.setState({ errors: { ...this.state.errors, [input]: bool } });
    }

    setSelected = (name, selectedValues, q, currentChecked) => {
        if (this.state[name].length < 3 || !currentChecked) {
            this.setState({ selected: { ...this.state.selected, [name]: selectedValues } },
                () => {
                    let selectedValues = this.state.selected[name].map((item, index) => {
                        return (item) ? questions[q][index].value : ""
                    }).filter(x => x);

                    if (q === 'question3') {
                        let selectedRank = this.state.selected[name].map((item, index) => {
                            return (item) ? questions[q][index].rank : ""
                        }).filter(x => x)

                        let rankTotal = 0;
                        if (selectedRank.length > 0) {
                            rankTotal = selectedRank.reduce((total, num) => {
                                return total + num;
                            });
                        }
                        this.setState({ [name]: selectedValues, rankTotal: { [name]: rankTotal } }, () => {
                            this.setError(name, false);
                        })
                    } else {
                        this.setState({ [name]: selectedValues }, () => {
                            this.setError(name, false);
                        })
                    }

                })
        }
    }

    onFormSubmit = (values, { setSubmitting,resetForm }) => {
        setTimeout(() => {
            this.submitSurveyData(values,setSubmitting,resetForm);
            
        }, 400);
    }

    submitSurveyData = async (values,setSubmitting,resetForm) => {
        let {persona, industry, companysize, vmchallenges,vmsolution,vmgoals,otherIndustries } = this.state;
        const questionOneObj = question1.find(o => o.value === this.state['persona']);
        const questionFourObj = question4.find(o => o.value === this.state['vmsolution']);
        const defenderObj = final.find(o => o.value === questionFourObj.value);
        vmsolution = questionFourObj.label;
        vmchallenges = vmchallenges.join();
        vmgoals = vmgoals.join();
        if(companysize == 4){
            companysize = 500000000
        }else if(companysize === 8){
            companysize = 5 * 1000000000
        }else if(companysize === 12){
            companysize = 10 * 1000000000
        }else if(companysize === 16){
            companysize = 15 * 1000000000
        }else if(companysize == 20){
            companysize = encodeURIComponent('$20B\+')
        }else{
            companysize = companysize * 1000000000
        }
        let data = JSON.stringify({
            'email': values.companyemail,
            'First Name': values.firstname,
            'Last Name': values.lastname,
            'Company': values.company,
            'Job Title': questionOneObj.label,
            'Persona': persona,
            'Industry': industry,
            'VM Challenges': vmchallenges,
            'VM Solution': vmsolution,
            'VM Goals': vmgoals,
            'Annual Revenue': '' + companysize + '',
            'Newsletter Checkbox': '' + values.isSubscribe + '',
            'Defender Type': defenderObj.value
          });

          if(industry === "Other" && otherIndustries.length !== 0){
            data = JSON.stringify({
                'email': values.companyemail,
                'First Name': values.firstname,
                'Last Name': values.lastname,
                'Company': values.company,
                'Job Title': questionOneObj.label,
                'Persona': persona,
                'Industry': otherIndustries,
                'VM Challenges': vmchallenges,
                'VM Solution': vmsolution,
                'VM Goals': vmgoals,
                'Annual Revenue': '' + companysize + '',
                'Newsletter Checkbox': '' + values.isSubscribe + '',
                'Defender Type': defenderObj.value
              });
          }
        const config = {
            method: 'post',
            url: 'https://cors-by-pass.kartik1092.workers.dev',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded', 
                "Access-Control-Allow-Origin": "*"
            },
            data : data
        };
        this.setChanged(false);
        this.setState({statusAPI:false,statusMessage:""})
        await axios(config).then((response) => {
            if(response.status === 200){
                this.setState({statusAPI:true,statusMessage:"Thank you for submitting form. You will get detail assesment on email."},() => {
                    setSubmitting(false);
                    resetForm();
                })
            }
          })
          .catch((error) => {
            this.setState({statusAPI:false,statusMessage:"There is error while submitting form!"},()=>{
                setSubmitting(false);
            })
          });
          
    }

    render() {

        const { persona, industry, companysize, vmchallenges, vmsolution, vmgoals, step, rankTotal, previousStep,stepsCompleted,statusAPI,statusMessage,changed,otherIndustries,innerWidth } = this.state;
        const values = { persona, industry, companysize, vmchallenges, vmsolution, vmgoals, step, rankTotal, previousStep,stepsCompleted,statusAPI,statusMessage,changed,otherIndustries,innerWidth };
        const selected = this.state.selected;
        const errors = this.state.errors;
        
        switch (step) {
            case 0:
                return (
                    <Intro
                        values={values}
                        nextStep={this.nextStep}
                        updateStep={this.updateStep}
                    />
                );
            case 1:
                return (
                    <Question1
                        name="persona"
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        updateStep={this.updateStep}
                        setError={this.setError}
                        handleChange={this.handleChange}
                        values={values}
                        errors={errors}
                    />
                )
            case 2:
                return (
                    <Question2
                        name="industry"
                        secondInputName="companysize"
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        updateStep={this.updateStep}
                        setError={this.setError}
                        handleSecondInputChange={this.handleChangeSlider}
                        values={values}
                        handleChange={this.handleChange}
                        errors={errors}
                    />
                )
            case 3:
                return (
                    <Question3
                        q="question3"
                        name="vmchallenges"
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        updateStep={this.updateStep}
                        setError={this.setError}
                        values={values}
                        errors={errors}
                        selected={selected}
                        setSelected={this.setSelected}
                    />
                )
            case 4:
                return (
                    <Question4
                        q="question4"
                        name="vmsolution"
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        updateStep={this.updateStep}
                        setError={this.setError}
                        handleChange={this.handleChange}
                        values={values}
                        errors={errors}
                    />
                )
            case 5:
                return (
                    <Question5
                        q="question5"
                        name="vmgoals"
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        updateStep={this.updateStep}
                        handleChange={this.handleChange}
                        setError={this.setError}
                        values={values}
                        errors={errors}
                        selected={selected}
                        setSelected={this.setSelected}
                        submitSurveyForm={this.submitSurveyForm}
                    />
                )
            case 6:
                return (
                    <Final
                        values={values}
                        errors={errors}
                        onFormSubmit={this.onFormSubmit}
                        updateStep={this.updateStep}
                        setRequestId={this.setRequestId}
                        setChanged={this.setChanged}
                    />
                )
            default:
                return (
                    <div>Nothing</div>
                );
        }
    }
}

export default SurveyForm;
