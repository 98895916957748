import React from 'react';

function Error({message, position}) {
    return (
        <div className={`flex justify-${position} pt-2`}>
            {(message) ? <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.49166 20H21.1507C22.3029 20 23.0084 18.7537 22.444 17.766L12.6145 0.740741C12.0502 -0.246914 10.6157 -0.246914 10.0513 0.740741L0.198302 17.7895C-0.366072 18.7772 0.339395 20 1.49166 20ZM11.3447 5.98471C11.9796 5.98471 12.4734 6.50206 12.4734 7.13698L12.2618 13.6273C12.2383 14.1446 11.8385 14.5444 11.3212 14.5444C10.8038 14.5444 10.4041 14.1446 10.3805 13.6273L10.1924 7.16049C10.1924 6.52557 10.7098 5.98471 11.3447 5.98471ZM11.3212 15.3674C11.9561 15.3674 12.497 15.8848 12.497 16.5432C12.497 17.1781 11.9796 17.719 11.3212 17.719C10.6863 17.719 10.1454 17.2016 10.1454 16.5432C10.1454 15.8848 10.6863 15.3674 11.3212 15.3674Z" fill="#FF2C2C" />
            </svg> : null}
            <p className="font-lato text-redorange text-xsmall capitalize ml-2">{message}</p>
        </div>
    );
}

export default Error;