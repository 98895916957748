import React from 'react';
import withStep from '../HOC/withStep';
import Nav from '../Nav/Nav';
import LeftSection from '../LeftSection/LeftSection';
import TextWithIconCard from '../Cards/TextWithIconCard';
import { questions, question3 } from '../../utils/questions';
import Copyright from '../Copyright/Copyright';
import QuestionHeader from '../QuestionHeader/QuestionHeader';
import Buttons from '../Buttons/Buttons';
import './Questions.css';

const Question3 = withStep(({ name, values, errors, selected, prevStep, nextStep, setError, setSelected, handleLocalChange, next, prev, updateStep }) => {
    return (
        <div className="main-wrapper question-three ios-safari-wrapper">
            {(values.innerWidth <= 768) ?  <div className='fixed z-30 w-full mobile-wrapper lg:hidden'>
                <Nav values={values} updateStep={updateStep} />
                <LeftSection values={values} />
                <QuestionHeader name={name} values={values} errors={errors} question={questions.question3}/>
            </div> : null}
            <div className="submain-wrapper">
                {(values.innerWidth > 768) ? <Nav values={values} updateStep={updateStep} /> : ''}
                <div
                    className="question-wrapper">
                    {(values.innerWidth > 768) ? <LeftSection values={values} /> : null}
                    <div className="relative col-span-2 part-two-content">
                        <div className="right-content">
                            {(values.innerWidth > 768) ? <QuestionHeader name={name} values={values} errors={errors} question={questions.question3} /> : ''}
                            <div className="question-3">
                                {question3.map(({ label, value, icon }, index) => {
                                    return (
                                        <TextWithIconCard
                                            q="question3"
                                            key={index}
                                            index={index}
                                            name={name}
                                            label={label}
                                            value={value}
                                            selected={selected}
                                            handleChange={handleLocalChange}
                                            icon={icon}
                                        />
                                    )
                                })}
                            </div>
                            <div className="button-wrapper md:mt-8 oxl:mt-8">
                                <Buttons name={name} values={values} prev={prev} next={next} tooltip="Please select up to three answers" />
                            </div>
                        </div>
                        <Copyright position="center" />
                    </div>
                </div>
            </div>
        </div>
    );
})

export default Question3;