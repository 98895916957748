export function updateSeasons(c){
    var html = document.getElementsByTagName("html")[0];
    // var sun = document.querySelector(".sun");
    var rainbow = document.querySelector(".rainbow");
    // var snow = document.querySelectorAll(".snow");
    var rain = document.querySelectorAll(".rain");
    var thunder = document.querySelectorAll(".thunder");
    var clouds = document.querySelectorAll(".cloud");
    var bgContainer = document.querySelector('.partone');
    var rainbowContainer = document.querySelector('.rainbow');
    var cloudGroup = document.querySelector('.cloudGroup');

    var cloudColours = ["#FFFFFF","#EBEBEB", "#EDF1F0", "#A2A2A2"];
    var seasons = ["rainbow","sunny","rain","storm"];
    var season;

    if(c == "none"){
        html.style.setProperty("--sun", "transparent");
        return;
    }
    html.style.setProperty("--cloud", cloudColours[c]);
    season = seasons[c];
    if (season === seasons[1] || season === seasons[0]) {
        for (var i = 0; i < clouds.length; i++) {
            clouds[i].style.display = "none";
        }
    } else {
        cloudGroup.classList.remove("hidden")
        for (var i = 0; i < clouds.length; i++) {
            clouds[i].style.display = "block";
        }
    }

    // flash clouds if season = storm
    // add lightning season = storm
    if (season === seasons[3]) {
        for (var i = 0; i < clouds.length; i++) {
        clouds[i].classList.add("flash");
        }
    } else {
        for (var i = 0; i < clouds.length; i++) {
        clouds[i].classList.remove("flash");
        }
    }
  
    //add sun season = sunny
    if (season === seasons[1]) {
        html.style.setProperty("--sun", "#FFC10A");
    } else {
        html.style.setProperty("--sun", "transparent");
    }

    if(season === seasons[0]){
        rainbow.classList.remove("hidden")
    }else{
        rainbow.classList.add("hidden")
    }

    rain.forEach(function (el) {
        season === seasons[2] || season === seasons[3]
        ? (el.style.display = "block")
        : (el.style.display = "none");
    });

    thunder.forEach(function (el){
        season === seasons[3]
        ? (el.style.display = "block")
        : (el.style.display = "none");
    })

    if(season === seasons[1]){
        bgContainer.classList.add("sunny")
        bgContainer.classList.remove("rainbowbg");
        bgContainer.classList.remove("rainy")
        bgContainer.classList.remove("storm")
    }else if(season === seasons[2]){
        bgContainer.classList.remove("rainbowbg");
        bgContainer.classList.remove("sunny")
        bgContainer.classList.add("rainy")
        bgContainer.classList.remove("storm")
    }else if(season === seasons[3]){
        bgContainer.classList.remove("rainbowbg");
        bgContainer.classList.remove("sunny")
        bgContainer.classList.remove("rainy")
        bgContainer.classList.add("storm")
    }else{
        bgContainer.classList.add("rainbowbg");
        rainbowContainer.classList.remove("hidden")
        bgContainer.classList.remove("sunny")
        bgContainer.classList.remove("rainy")
        bgContainer.classList.remove("storm")
    }
}