import React, { useLayoutEffect } from 'react';
import { updateSeasons } from '../../utils/helpers/updateSeasons';
import { question1, question5, question4, question2 } from '../../utils/questions';
import FoxSVG from '../SVG/FoxSVG';
import LionSVG from '../SVG/LionSVG';
import PandaSVG from '../SVG/PandaSVG';
import UnicornSVG from '../SVG/UnicornSVG';
import insights from '../../images/question4/icons/insights.svg';
import risk from '../../images/question4/icons/risk.svg';
import scanner from '../../images/question4/icons/scanner.svg';
import systematic from '../../images/question4/icons/systematic.svg';
import finance from '../../images/question2/finance.svg';
import pservices from '../../images/question2/pservices.svg';
import pharma from '../../images/question2/pharma.svg';
import technology from '../../images/question2/technology.svg';
import manufacturing from '../../images/question2/manufacturing.svg';
import automotive from '../../images/question2/automotive.svg';
import hospitality from '../../images/question2/hospitality.svg';
import energy from '../../images/question2/energy.svg';
import government from '../../images/question2/government.svg';
import other from '../../images/question2/others.svg';
import goals from '../../images/question5/icons/goals.svg';
import reporting from '../../images/question5/icons/reporting.svg';
import management from '../../images/question5/icons/management.svg';
import vmprogram from '../../images/question5/icons/vmprogram.svg';
import securityposture from '../../images/question5/icons/securityposture.svg';
import riskposture from '../../images/question5/icons/riskposture.svg';
import ml from '../../images/question5/icons/ml.svg';
import itenvironment from '../../images/question5/icons/itenvironment.svg';
import emergingthreats from '../../images/question5/icons/emergingthreats.svg';
import objectives from '../../images/question5/icons/objectives.svg';
import perfomance from '../../images/question5/icons/perfomance.svg';
import workflows from '../../images/question5/icons/workflows.svg';
import buildings from '../../images/intro/buildings.svg';
import Cloud from '../../components/SVG/Cloud';

import './LeftSection.css';

const components = {
    fox: FoxSVG,
    lion: LionSVG,
    panda: PandaSVG,
    unicorn: UnicornSVG,
}

const icons = {
    insights,
    risk,
    scanner,
    systematic,
    goals,
    reporting,
    management,
    vmprogram,
    securityposture,
    riskposture,
    ml,
    itenvironment,
    emergingthreats,
    objectives,
    perfomance,
    workflows
}

const images = {
    finance,
    pharma,
    technology,
    manufacturing,
    automotive,
    hospitality,
    energy,
    government,
    pservices,
    other
}

function getIconByKey(key) {
    return icons[key];
}

function LeftSection({ values }) {
    const a = 7; //rainbowcolor 
    const b = 4; //cloud 
    const d = 30; //rain
    let season = 0;

    useLayoutEffect(() => {
        //calculate weather for question 3 left section 
        if (values.step >= 3) {
            //let seasonPercentage = (values.rankTotal.vmchallenges / (questions.question3.maxrank * questions.question3.selectedlimit)) * 100;
            let selectedOption = values.vmchallenges.length
            if (selectedOption === 1) {
                season = 1;
            } else if (selectedOption === 2) {
                season = 2;
            } else if (selectedOption === 3) {
                season = 3;
            } else {
                season = 0;
            }   
            updateSeasons(season)
        }else{
            updateSeasons(0)
        }
        
    }, [values.rankTotal.vmchallenges,season])
    const questionOneObj = question1.find(o => o.value === values['persona']);
    const questionTwoObj = question2.find(o => o.value === values['industry']);
    const questionFourObj = (values['vmsolution']) ? question4.find(o => o.value === values['vmsolution']) : '';
    const questionFiveValues = values['vmgoals'].map((value) => {
        return {label: question5.find(o => o.value === value).label, icon: question5.find(o => o.value === value).icon}
    });
    const Avatar = components[questionOneObj.img];

  
    let avatarClass;
    if (values.step === 4 && values.previousStep !== 5) {
        // if go to question 4 and not coming from question 5, zoom animation
        avatarClass = "avtar zoommobile";
    } else if (values.previousStep === 4 && values.step === 3) {
        // if go to question 3 from question 4 then reverse zoom animation
        avatarClass = "avtar zoommobile reverse-animation";
    } else if (values.step === 5 || (values.previousStep === 5 && values.step === 4)) {
        // if go to question 5 or 
        // if go to question 4 from question 5, no animation
        avatarClass = "avtar zoomwithoutanimation";   
    } else {
        avatarClass = "avtar";
    }
    let thoughtBubble1;
    if (values.step === 4 && values.previousStep !== 5 && questionFourObj) {
        // if go to question4 and not coming from question 5 and selected 
        thoughtBubble1 = "thought-bubble-1 animated1 delay1"
    } else if (values.step === 3 && values.previousStep === 4) {
        //if go to question3 from question 4 , reverse animation
        thoughtBubble1 = "thought-bubble-1 animated1 reverse-animation"
    } else if (values.step === 5 && questionFiveValues.length > 0) {
        //if go to question5 and selected anything , animation
        thoughtBubble1 = "thought-bubble-1 animated2 delay1"
    }else if (values.step === 5 && questionFiveValues.length === 0) {
        //if go to question5 and not selected anything , noanimation
        thoughtBubble1 = "thought-bubble-1 noanimated"
    }else if(values.step === 4 && values.previousStep == 5 && questionFourObj && questionFiveValues.length === 0){
        //if go to question 4 from question 5 and selected anything then no animation
        thoughtBubble1 = "thought-bubble-1 noanimated"
    }else if (values.step == 4 && values.previousStep == 5 && questionFiveValues.length > 0) {
        //if got to question 4 from question 5
        thoughtBubble1 = "thought-bubble-1 animated2 reverse-animation"
    } else {
        thoughtBubble1 = "thought-bubble-1 hidden"
    }

    let thoughtBubble2;
    if (values.step === 5 && questionFiveValues.length > 0) {
        thoughtBubble2 = "thought-bubble-2 animated1 delay1"
    } else if (values.step == 4 && values.previousStep == 5 && questionFiveValues.length > 0) {
        thoughtBubble2 = "thought-bubble-2 animated1 reverse-animation"
    } else {
        thoughtBubble2 = "thought-bubble-2 hidden"
    }
    
    return (
        <div className="leftSectionWrapper">
            <div className="leftSectionContainer">
                <div className='partone rainbowbg'>
                    <div className="sun"></div>
                    <div className={`cloudGroup hidden`}>
                        {[...Array(b)].map((e, j) => <div key={j} className="cloud">
                            <Cloud/>
                            <div className="weatherContainer">
                                <div className="thunder"></div>
                                {[...Array(d)].map((e, l) => <div key={l} className="rain"></div>)}
                            </div>
                        </div>)}
                    </div>
                    <div className="group-of-weather">
                        <div className="hidden rainbow">
                            {[...Array(a)].map((e, i) => <div key={i} className="rainbow-colour"></div>)}
                        </div>
                        <div className={`buildings-container ${((questionTwoObj) && questionTwoObj.img) ? questionTwoObj.img : ''} ${questionTwoObj ? 'selected':''}`}>
                            {/* {(questionTwoObj) ? <img src={getImageByKey(questionTwoObj.img)} alt=""/> :  <img src={buildings} alt="" />} */}
                            <img src={buildings} className={`${(questionTwoObj) ? 'opacity-0 hidden' : 'opacity-100 block'}`} alt="" />
                            {question2.map(({img}) => {
                                return(
                                    <img src={images[img]} className={`${((questionTwoObj) && images[img]===images[questionTwoObj.img]) ? 'opacity-100 block' : 'opacity-0 hidden'}`} alt=""/>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='parttwo'>
                    <div className={`${avatarClass} ${questionOneObj.img}`}>
                        <Avatar />
                    </div>
                    <div className="thought-bubble-container">
                        {(questionFourObj) ?
                            <div className={thoughtBubble1}>
                                <div className='card-content'>
                                    <img src={getIconByKey(questionFourObj.icon)} alt="" />
                                    <p>{questionFourObj.label}</p>
                                </div>
                            </div> : null}
                            <div className={thoughtBubble2}>
                            {(questionFiveValues.length > 0) ?
                                <div className='card-content'>
                                    {questionFiveValues.map(({label,icon},index) => {
                                        return (<div className="card-row" key={index}>
                                                <img src={getIconByKey(icon)} alt="" />
                                                <p>{label}</p>
                                            </div>)
                                    })}
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeftSection;