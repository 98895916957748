import React, { useLayoutEffect } from 'react';
import Error from '../Error/Error';
import {animateScroll as scroll} from 'react-scroll';
import './QuestionHeader.css';

function QuestionHeader({name,errors,question,showSubtitle}) {
    useLayoutEffect(() => {
        scroll.scrollToTop();
    },[])
    return (
        <div className='question-header-wrapper'>
            <h2 className={`title text-center mt-6 px-4 xl:mt-0 xl:px-26 ${(name === 'industry') ? 'oxl:px-20' : 'oxl:px-32'} font-robotoslab font-black`}>{question.title}</h2>
            {question.subtitle ? <div className="subtitle-2">
                <p className="mb-3">{question.subtitle}</p>
                {errors[name] ? <Error message="You have not selected anything!" position="center" /> : null}
            </div> : null}
        </div>
    );
}

export default QuestionHeader;