import React from 'react';
import logo from '../../images/mobile-logo.svg';
import Steps from '../Steps/Steps';
import './Nav.css';

function Nav(props) {
    return (
        <div className="navbar-container">
            <div className='navbar'>
                <div className="logo-container p-3">
                    <img src={logo} className="w-full" alt="" />
                </div>
                <Steps step={props.values.step} stepsCompleted={props.values.stepsCompleted} updateStep={props.updateStep} />
            </div>
        </div>
    );
}

export default Nav;