import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import withStep from '../HOC/withStep';
import TextWithIconCardRadio from '../Cards/TextWithIconCardRadio';
import { questions, question2, question21 } from '../../utils/questions';
import Nav from '../Nav/Nav';
import LeftSection from '../LeftSection/LeftSection';
import Copyright from '../Copyright/Copyright';
import QuestionHeader from '../QuestionHeader/QuestionHeader';
import { Element } from 'react-scroll';
import './Questions.css';
import Buttons from '../Buttons/Buttons';

const sliderHandlestyle = {
    height: "36px",
    width: "36px",
    backgroundColor: "rgb(214, 244, 250)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    marginTop: "-12px"
};

const range = {
    0: '$0M',
    20: '$20B+'
};

const SliderToolTip = createSliderWithTooltip(Slider);

const Question2 = withStep(({ name, secondInputName, values, errors, prevStep, nextStep, setError, handleChange, handleSecondInputChange, next, prev, updateStep }) => {

    return (
        <div className="main-wrapper question-two ios-safari-wrapper">
            {(values.innerWidth <= 768) ?  <div className='fixed z-30 w-full mobile-wrapper lg:hidden'>
                <Nav values={values} updateStep={updateStep} />
                <LeftSection values={values} />
                <QuestionHeader name={name} values={values} errors={errors} question={questions.question2}/>
            </div> : null}
           
            <div className="submain-wrapper desktop-wrapper">
                {(values.innerWidth > 768) ? <Nav values={values} updateStep={updateStep} /> : ''}
                <div className="question-wrapper">
                    {(values.innerWidth > 768) ? <LeftSection values={values} /> : null}
                    <div className="relative col-span-2 part-two-content">
                        <div className="right-content">
                            {(values.innerWidth > 768) ? <QuestionHeader name={name} values={values} errors={errors} question={questions.question2}/> : ''}
                            <Element name="question2">
                                <p className="mt-2 text-center">Select your industry.</p>
                            </Element>
                            <div className="question-2 section-1">
                            { values[name] != "Other"? 
                            question21.map(({ label, value, icon }, index) => {
                                return (
                                    <TextWithIconCardRadio
                                        key={index}
                                        name={name}
                                        label={label}
                                        value={value}
                                        values={values}
                                        handleChange={handleChange}
                                        icon={icon}
                                    
                                    />
                                )
                            }) : 
                            question2.map(({ label, value, icon }, index) => {
                                return (
                                    <TextWithIconCardRadio
                                        key={index}
                                        name={name}
                                        label={label}
                                        value={value}
                                        values={values}
                                        handleChange={handleChange}
                                        icon={icon}
                                    />
                                )
                            })}
                                
                                
                                { values[name] === "Other"? <div className="relative col-span-2 px-4 md:px-0 otheroption">
                                    {<input type="text" onChange={handleChange("otherIndustries")} name="industry" placeholder="Please specify" className="relative w-full py-3 pl-6 text-black bg-white h-14 placeholder-alto font-lato text-button"/>}
                                </div> : null}
                            </div>
                            <div className="question-2 section-2">
                                <Element name="question2">
                                    <p className="mt-6 mb-16 text-center">Select annual revenue.</p>
                                </Element>
                                <div className="flex">
                                    <p className="text-button pr-7">{range[0]}</p>
                                    <SliderToolTip
                                        className=""
                                        min={0}
                                        max={20}
                                        defaultValue={0}
                                        step={4}
                                        startPoint={0}
                                        onChange={handleSecondInputChange(secondInputName)}
                                        value={values[secondInputName]}
                                        dots={true}
                                        dotStyle={{ height: "12px", width: "0px", bottom: "-8px", border: "0", borderColor: "#CFCFCF", borderRight: "1px solid #CFCFCF" }}
                                        activeDotStyle={{ borderColor: "#CFCFCF" }}
                                        tipFormatter={value => 
                                            {
                                                if(value === 0){
                                                    return '$0M';
                                                }else if(value === 4){
                                                    return '<$500M';
                                                }else if(value === 8){
                                                    return '$5B';
                                                }else if(value === 12){
                                                    return '$10B';
                                                }else if(value === 16){
                                                    return '$15B';
                                                }else if(value === 20){
                                                    return '$20B+';
                                                }else{
                                                    return '$' + value + 'B';
                                                }
                                            }
                                        }
                                        trackStyle={{ backgroundColor: "#FFFFFF", border: "1px solid #CFCFCF", borderLeft: "0", borderRight: "0", borderRadius: "12px", height: "12px" }}
                                        handleStyle={sliderHandlestyle}
                                        railStyle={{ backgroundColor: "#FFFFFF", border: "1px solid #CFCFCF", borderRight: "0", borderLeft: "0", borderRadius: "12px", height: "12px" }}
                                        tipProps={{ visible: values[secondInputName] }}
                                    />
                                    <p className="text-button pl-7">{range[20]}</p>
                                </div>
                            </div>
                            <div className="button-wrapper oxl:mt-8">
                                <Buttons name={name} values={values} prev={prev} next={next} tooltip={`${(values.companysize === 0 && (values[name].length !== 0 && values[name] !== "Other" )) ? "Please select annual revenue" : "Please select atleast one industry"}`} />
                            </div>
                        </div>
                        <Copyright position="center" />
                    </div>
                </div>

            </div>
        </div>
    );
})

export default Question2;